import {
  ShadowContainer,
  Heading,
  LoadingSpinner,
  IWarrantyLogoIcon,
} from '@rabbit/elements/shared-components';
import AuthForm from '../components/templates/AuthForm/AuthForm';
import { useState } from 'react';
import { useAppInfo } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

function LoginView() {
  const appInfo = useAppInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState('');
  const { t } = useTranslation();

  if (isLoading || t('CFG_COBRAND_NAME', '') === '')
    return <LoadingSpinner extraClasses={'mt-2'} size={'sm'} />;

  return (
    <div className="flex flex-grow flex-col justify-around">
      <ShadowContainer>
        <div className="px-8 py-6">
          <img
            src={appInfo.logo}
            alt={appInfo.name}
            className="mt-8 h-auto max-w-[200px]"
          ></img>
          <Heading kind={'h2'} className="mt-5">
            {t('sage_auth_strapline', 'Warranty Intelligence Portal')}
          </Heading>
          <div className="mt-4 mb-5">
            <p className="font-nunito text-lg font-medium">Log in</p>
          </div>
          <AuthForm
            kind="Login"
            setIsLoading={setIsLoading}
            authErrorMsg={authErrorMsg}
            setAuthErrorMsg={setAuthErrorMsg}
          />
          {/* <div className="my-4 text-base font-nunito">
            No account? &nbsp;
            <NavLink
              to={ROUTE_NAME.SIGNUP}
              className="text-primary-600"
              reloadDocument
            >
              Create one
            </NavLink>
          </div> */}
        </div>
        <div className="flex flex-col items-end bg-gray-100 px-8 py-6">
          <div className="font-caudex text-sm text-gray-600">
            {t('Powered by')}
          </div>
          <div className="-mt-2 flex items-center">
            <IWarrantyLogoIcon className="mr-2" />
            <p className="font-caudex text-2xl text-gray-900">iWarranty</p>
          </div>
        </div>
      </ShadowContainer>
    </div>
  );
}

export default LoginView;
