import { TrashIcon } from '@heroicons/react/24/outline';
import { AppInfoShape } from '@rabbit/data/types';
import {
  ButtonIcon,
  Input,
  Button,
  Modal,
  Heading,
} from '@rabbit/elements/shared-components';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { LogForms } from '../ClaimCostModal';
import { CaseflowInterface } from '@rabbit/sage/context/CaseflowContext';
import { FieldArray } from 'formik';
import { CFCF_PartsUsed } from '@rabbit/bizproc/core';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { t } from 'i18next';

const PartUsedForm = (
  appInfo: AppInfoShape,
  { caseFacts, alterCaseFacts }: CaseflowInterface,
  personaId: string,
  option?: { editMode: boolean; index: number }
): LogForms<{ parts: CFCF_PartsUsed }> => ({
  name: 'parts-used',
  initialValues: {
    parts: [
      {
        cost: {
          currency: appInfo.currency,
        },
      },
    ],
  },
  schema: Yup.object().shape({
    parts: Yup.array(
      Yup.object({
        description: Yup.string()
          .required(t('Please enter description'))
          .trim(),
        name: Yup.string().required(t('Please enter a part name')).trim(),
        manufacturer: Yup.string().required('Please enter manufacturer').trim(),
        id: Yup.string().required(t('Please enter a model id')).trim(),
        quantity: Yup.string().required(t('Please enter part quantity')).trim(),
        cost: Yup.object()
          .shape({
            amount: Yup.string().required(t('Please enter a part cost')).trim(),
            currency: Yup.string(),
          })
          .required(t('Please enter a part cost')),
        VAT: Yup.string().required(t('Please enter VAT')),
      })
    ),
  }),
  onDelete: async (index, onComplete) => {
    const registry = caseFacts?.parts_used_cost_data || [];
    registry.splice(index, 1);
    try {
      await alterCaseFacts({ parts_used_cost_data: [...registry] });
      toast.success(t('Record deleted successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  onSubmit: async (values, onComplete) => {
    const parts = values.parts;
    const registry: CFCF_PartsUsed = caseFacts?.parts_used_cost_data || [];
    if (option?.editMode) {
      registry[option.index] = parts[0];
    } else {
      parts.forEach((part) => {
        registry.push(part);
      });
    }
    try {
      await alterCaseFacts({
        parts_used_cost_data: [...registry],
        ...(values.internal_comment && {
          internal_comment: {
            comment: values.internal_comment,
            author: personaId,
          },
        }),
      });
      toast.success(t('Claim updated successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    } finally {
      onComplete();
    }
  },
  Form: ({ setFieldValue }) => {
    const [parts, setParts] = useState<string[]>(['part.0']);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
      useState<boolean>(false);
    const [confirmationModalSettings, setConfirmationModalSettings] = useState({
      text: t(
        'You are about to delete this row. Are you sure you want to continue?'
      ),
      primaryButtonText: t('Yes'),
      outlineButtonText: t('No, cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () => {
        // will set in the future from the form
      },
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    });

    const handleAddPart = (push: (args: any) => void) => {
      setParts((parts) => {
        parts.push('part.' + Math.random().toString(32));
        return parts;
      });
      push({
        cost: {
          currency: appInfo.currency,
        },
      });
    };

    const handleReset = () => {
      void setFieldValue('parts.0.description', '');
      void setFieldValue('parts.0.name', '');
      void setFieldValue('parts.0.manufacturer', '');
      void setFieldValue('parts.0.id', '');
      void setFieldValue('parts.0.quantity', '');
      void setFieldValue('parts.0.cost', {
        amount: '',
      });
      void setFieldValue('parts.0.VAT', '');
    };

    return (
      <>
        <div className="font-nunito font-lg font-bold">{t('Parts used')}</div>
        <FieldArray name="parts">
          {({ push, remove }) => (
            <>
              {parts.map((part, index) => (
                <div
                  key={part}
                  className={`flex flex-col gap-4 ${
                    !option ? 'border-b border-gray-300 pb-4' : ''
                  }`}
                >
                  <div className="flex">
                    <Input
                      type="text"
                      name={`parts.${index}.name`}
                      settings={{
                        placeholder: t('Part name') + '*',
                      }}
                    />
                  </div>
                  <div className="flex">
                    <Input
                      type="text"
                      name={`parts.${index}.description`}
                      settings={{
                        placeholder: t('Part description') + '*',
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-[6fr_3fr_2fr_4fr_4fr_3fr] gap-4">
                    <Input
                      type="text"
                      name={`parts.${index}.manufacturer`}
                      settings={{
                        placeholder: t('Manufacturer') + '*',
                      }}
                    />
                    <Input
                      type="text"
                      name={`parts.${index}.id`}
                      settings={{ placeholder: t('ID') + '*' }}
                    />
                    <Input
                      type="text"
                      name={`parts.${index}.quantity`}
                      settings={{
                        placeholder: t('Qty') + '*',
                      }}
                    />
                    <Input
                      type="currency"
                      name={`parts.${index}.cost`}
                      settings={{
                        id: `parts.${index}.cost`,
                        placeholder: t('Amount') + '*',
                        disableCurrency: true,
                        currency: appInfo.currency,
                      }}
                    />
                    <Input
                      type="text"
                      name={`parts.${index}.VAT`}
                      settings={{
                        placeholder: t('VAT') + '*',
                        append: '%',
                      }}
                    />
                    {parts.length === 1 ? (
                      <ButtonIcon
                        Icon={XMarkIcon}
                        label={t('Clear all')}
                        iconLeft
                        kind="bgLightGreen"
                        onClick={handleReset}
                      />
                    ) : (
                      <ButtonIcon
                        Icon={TrashIcon}
                        label=""
                        onClick={() => {
                          setConfirmationModalSettings({
                            ...confirmationModalSettings,
                            handlePrimaryClick: async () => {
                              await remove(index);
                              setParts((parts) => {
                                const filter = parts.filter(
                                  (item) => item !== part
                                );
                                return filter;
                              });
                              setIsConfirmationModalOpen(false);
                            },
                          });
                          setIsConfirmationModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                  <Heading kind="p" className="text-gray-500">
                    * {t('required')}
                  </Heading>
                  {isConfirmationModalOpen && (
                    <Modal
                      className="h-fit w-[350px] rounded-md bg-white"
                      kind={'pop-up'}
                      settings={confirmationModalSettings}
                    />
                  )}
                </div>
              ))}
              {!option && (
                <Button
                  kind="primary"
                  className="flex w-fit items-center gap-2"
                  onClick={() => handleAddPart(push)}
                >
                  <PlusIcon className="h-4 w-4 text-white" />
                  {t('Add parts')}
                </Button>
              )}
            </>
          )}
        </FieldArray>
      </>
    );
  },
});

export default PartUsedForm;
