import { useContext } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  Table,
  formatUnixTime,
  getCurrencyFormat,
} from '@rabbit/elements/shared-components';
import { calculateTotalValue, useAppInfo } from '../../../../utils/helpers';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import {
  liteAdministrativeTimeColumns,
  liteOtherColumns,
  litePartsUsedColumns,
  litePostageColumns,
  liteRepairTimeColumns,
  liteTravelColumns,
} from './ClaimCostsSectionTableColumns';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';
import { UserUploadedDocument } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import {
  CaseFactsShape,
  CFCF_AdministrativeCostLog,
  CFCF_OtherCostLog,
  CFCF_TravelCostLog,
  ClaimPartUsed,
} from '@rabbit/bizproc/core';
import { ConfigContext } from '@rabbit/config/context';
import { DocumentModalShape, LogClaimEditShape } from './ClaimCostsSection';

export interface ClaimCostsSectionDefaultProps {
  caseFacts: Partial<CaseFactsShape>;
  setLogCostModal: (value: boolean) => void;
  setEditModal: (value: LogClaimEditShape | undefined) => void;
  setDocumentModal: (value: DocumentModalShape | undefined) => void;
}

export function ClaimCostsSectionDefault({
  caseFacts,
  setDocumentModal,
  setEditModal,
  setLogCostModal,
}: ClaimCostsSectionDefaultProps) {
  const appInfo = useAppInfo();
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  /* -------------------------------------------------------------------------- */
  /*                             Results formatting                             */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Parts used ------------------------------- */
  const getPartsUserArrayParsed = () => {
    const partsUsedArray: ClaimPartUsed[] =
      caseFacts?.parts_used_cost_data ?? [];

    const partsUserArrayParsed: {
      data: object;
      manufacturer: string;
      description: string;
      part_name: string;
      model_id: string;
      parts_quantity: string;
      parts_cost: string;
      parts_cost_vat: string;
      subtotal: string;
    }[] = [];
    partsUsedArray.forEach((result, index) => {
      partsUserArrayParsed.push({
        data: { ...result, index },
        description: result?.description ?? '-',
        part_name: result?.name,
        manufacturer: result?.manufacturer as any,
        model_id: result?.id,
        parts_quantity: String(result?.quantity),
        parts_cost: result?.cost
          ? getCurrencyFormat(result?.cost?.amount, result?.cost?.currency)
          : '-',
        parts_cost_vat: result?.VAT ? result?.VAT + '%' : '-',
        subtotal:
          getCurrencyFormat(
            Number(result?.quantity) * Number(result?.cost?.amount),
            result?.cost?.currency
          ) ?? '-',
      });
    });
    return partsUserArrayParsed;
  };

  const partsUserArrayParsed: any[] = getPartsUserArrayParsed();
  const partsUsedTotalCost = partsUserArrayParsed.reduce((acc, curr) => {
    return (
      acc +
      //@ts-ignore todo: review this
      (curr.subtotal ? parseFloat(curr.subtotal.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* --------------------------- Administrative time -------------------------- */

  // TODO: CHECK THE RATE. WAS SET TO AN ENV VAR BASED ON SHELTA PREVIOUSLY
  const RATE = 15;
  const getFormattedResultAdministrativeTime = () => {
    const formattedResultAdministrativeTime: {
      data: object;
      description: string;
      repairer_id: string;
      total_repairing_time: string;
      timeStamp: string;
      cost: string;
      vat: string;
    }[] = [];
    caseFacts.administrative_cost_data?.forEach(
      (result: CFCF_AdministrativeCostLog, i) => {
        formattedResultAdministrativeTime.push({
          data: { ...result, index: i },
          description: result?.description || '-',
          repairer_id: result?.team_member.name || '-',
          total_repairing_time: result?.time_spent || '-',
          timeStamp: result?.date
            ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
            : '-',
          cost: result?.time_spent
            ? getCurrencyFormat(result?.rate.amount || 0, appInfo.currency)
            : '-',
          vat: result?.VAT ? result?.VAT + '%' : '-',
        });
      }
    );
    return formattedResultAdministrativeTime;
  };

  const formattedResultAdministrativeTime =
    getFormattedResultAdministrativeTime();

  /* ----------------------------- Assessment time ---------------------------- */
  const assessmentTime = [
    {
      repairer: caseFacts?.delegate_repairer_name,
      time_spent_assessing: caseFacts?.time_spent_assessing,
      cost: caseFacts?.time_spent_assessing,
      timeStamp: Date.now(),
    },
  ];
  const formattedResultAssessmentTime: {
    data: object;
    repairer_id: string;
    total_repairing_time: string;
    timeStamp: string;
    cost: string;
  }[] = [];
  assessmentTime.forEach((result) => {
    if (result?.time_spent_assessing) {
      if (typeof result?.time_spent_assessing === 'string') {
        formattedResultAssessmentTime.push({
          data: result,
          repairer_id: result?.repairer || '-',
          total_repairing_time: result?.time_spent_assessing || '-',
          timeStamp: result?.timeStamp
            ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
            : '-',
          cost: result?.time_spent_assessing
            ? getCurrencyFormat(
                calculateTotalValue(result?.time_spent_assessing, RATE),
                appInfo.currency
              )
            : '-',
        });
      } else {
        result?.time_spent_assessing.forEach((timeSpent) => {
          formattedResultAssessmentTime.push({
            data: result,
            repairer_id: timeSpent.user || '-',
            total_repairing_time: timeSpent.time || '-',
            timeStamp: result?.timeStamp
              ? formatUnixTime(result?.timeStamp, 'dd/MM/yyyy')
              : '-',
            cost: timeSpent.time
              ? getCurrencyFormat(
                  calculateTotalValue(timeSpent.time, RATE),
                  appInfo.currency
                )
              : '-',
          });
        });
      }
    }
  });

  const finalArrayForAdministrativeTimeTable: any[] =
    formattedResultAssessmentTime.concat(formattedResultAdministrativeTime);

  const administratitveTotalCost = finalArrayForAdministrativeTimeTable.reduce(
    (acc, cur) => {
      return (
        acc + calculateTotalValue(cur.data.time_spent, cur.data.rate.amount)
      );
    },
    0
  );

  /* ------------------------------- Repair time ------------------------------ */
  const getFormattedResultRepairTime = () => {
    const repairTime = caseFacts?.repair_time_data || [];
    const formattedResultRepairTime: {
      data: object;
      description: string;
      repairer_id: string;
      total_repairing_time: string;
      timeStamp: string;
      cost: string;
      vat: string;
    }[] = [];
    repairTime.forEach((result, i) => {
      const formattedResult = {
        data: { ...result, index: i },
        description: result?.description || '-',
        repairer_id: result?.team_member.name || '-',
        total_repairing_time: result?.time_spent || '-',
        timeStamp:
          result?.date && result?.date !== 0
            ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
            : '-',
        cost: result?.rate?.amount
          ? getCurrencyFormat(result.rate.amount ?? 0, result?.rate.currency)
          : '-',
        vat: result?.VAT ? result?.VAT + '%' : '-',
      };

      formattedResultRepairTime.push(formattedResult);
    });
    return formattedResultRepairTime;
  };

  // todo type
  const formattedResultRepairTime: any[] = getFormattedResultRepairTime();

  const repairTotalCost =
    caseFacts?.repair_time_data?.reduce((acc, curr) => {
      return (
        acc +
        calculateTotalValue(
          curr.time_spent,
          curr.rate?.amount ? curr.rate.amount : 0
        )
      );
    }, 0) || 0;

  /* --------------------------------- Postage -------------------------------- */
  const getFormattedResultPostage = () => {
    const postage = caseFacts?.shipping_cost_data || [];
    const formattedResultPostage: {
      data: object;
      description: string;
      carrier: string;
      tracking_number: string;
      date: string;
      cost: string;
      vat: string;
      attachments: UserUploadedDocument[];
    }[] = [];
    postage.forEach((result, i) => {
      formattedResultPostage.push({
        data: { ...result, index: i },
        description: result?.description || '-',
        carrier: (result?.carrier as any) || '-',
        tracking_number: result?.tracking_number || '-',
        date: result?.date
          ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
          : '-',
        cost: result?.amount
          ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
          : '-',
        vat: String(result?.VAT || '-'),
        attachments: result?.documents || [],
      });
    });
    return formattedResultPostage;
  };

  const formattedResultPostage: any[] = getFormattedResultPostage();
  const postageTotalCost = formattedResultPostage.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* --------------------------------- Travel --------------------------------- */
  const travel = caseFacts?.travel_cost_data;
  const formattedResultTravel: {
    data: object;
    description: string;
    date: string;
    cost: string;
    vat: string;
    attachments: UserUploadedDocument[];
  }[] = [];
  travel?.forEach((result: CFCF_TravelCostLog, i) => {
    formattedResultTravel.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  const travelTotalCost = formattedResultTravel.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  /* ---------------------------- Other claim costs --------------------------- */
  const other = caseFacts?.other_cost_data || [];
  const formattedResultOther: {
    data: object;
    description: string;
    date: string;
    cost: string;
    vat: string;
    attachments: UserUploadedDocument[];
  }[] = [];
  other.forEach((result: CFCF_OtherCostLog, i) => {
    formattedResultOther.push({
      data: { ...result, index: i },
      description: result?.description || '-',
      date: result?.date
        ? formatUnixTime(Number(result?.date), 'dd/MM/yyyy')
        : '-',
      cost: result?.amount
        ? getCurrencyFormat(result?.amount?.amount, result?.amount?.currency)
        : '-',
      vat: result?.VAT ? String(result?.VAT) : '-',
      attachments: result?.documents || [],
    });
  });

  const otherTotalCost = formattedResultOther.reduce((acc, curr) => {
    return (
      acc + (curr.cost ? parseFloat(curr.cost.replace(/[^\d.-]/g, '')) : 0)
    );
  }, 0);

  const totalCostFromClaim = [
    administratitveTotalCost,
    repairTotalCost,
    partsUsedTotalCost,
    postageTotalCost,
    travelTotalCost,
    otherTotalCost,
  ].reduce((a, b) => a + b, 0);

  const renderTableCost = (label: string, columns: any, data: any) => {
    return (
      <div>
        <CardWrapperWithHeader
          title={label}
          canCollapse={true}
          collapsedByDefault={true}
          smaller={true}
          noPadding={true}
        >
          <div className="max-w-[757px] rounded-lg border border-gray-100">
            <Table
              columns={columns}
              data={data}
              inner={true}
              enablePagination={data.length > 10}
              initialState={{
                showGlobalFilter: true,
              }}
              muiSearchTextFieldProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiTableHeadCellProps={{
                className: 'relative bg-gray-200 uppercase font-light',
              }}
              muiTableBodyCellProps={{
                className: 'px-4 py-0',
              }}
            />
          </div>
        </CardWrapperWithHeader>
      </div>
    );
  };

  const currencySymbol =
    LIST_CURRENCIES.find((i) => i.code === appInfo.currency)?.symbolNative ||
    '';

  return (
    <CardWrapperWithHeader
      title={`${t('Claim costs')} - ${t('Total')}: ${getCurrencyFormat(
        totalCostFromClaim || 0,
        appInfo.currency
      )}`}
      collapsedByDefault={false}
      headerRight={
        <div className="flex gap-4">
          <ButtonIcon
            type="primary"
            label={t(`Log claim cost`)}
            Icon={WrenchScrewdriverIcon}
            iconLeft
            onClick={() => {
              setLogCostModal(true);
            }}
          />
        </div>
      }
    >
      <div className="font-nunito flex flex-col gap-6">
        {administratitveTotalCost > 0 &&
          renderTableCost(
            `${'Administrative time'} - ${'Total'}: ${getCurrencyFormat(
              administratitveTotalCost || 0,
              appInfo.currency
            )}`,
            liteAdministrativeTimeColumns(setEditModal, currencySymbol),
            finalArrayForAdministrativeTimeTable
          )}
        {repairTotalCost > 0 &&
          renderTableCost(
            `${'Repair time'} - ${'Total'}: ${getCurrencyFormat(
              repairTotalCost || 0,
              appInfo.currency
            )}`,
            liteRepairTimeColumns(setEditModal, currencySymbol),
            formattedResultRepairTime
          )}
        {partsUsedTotalCost > 0 &&
          renderTableCost(
            `${t('Parts used')} - ${'Total'}: ${getCurrencyFormat(
              partsUsedTotalCost || 0,
              appInfo.currency
            )}`,
            litePartsUsedColumns(setEditModal),
            partsUserArrayParsed
          )}
        {postageTotalCost > 0 &&
          renderTableCost(
            `${t('Postage')} - ${'Total'}: ${getCurrencyFormat(
              postageTotalCost || 0,
              appInfo.currency
            )}`,
            litePostageColumns(setEditModal, setDocumentModal),
            formattedResultPostage
          )}

        {travelTotalCost > 0 &&
          renderTableCost(
            `${'Travel'} - ${'Total'}: ${getCurrencyFormat(
              travelTotalCost || 0,
              appInfo.currency
            )}`,
            liteTravelColumns(setEditModal, setDocumentModal),
            formattedResultTravel
          )}
        {otherTotalCost > 0 &&
          renderTableCost(
            `${'Other'} - ${'Total'}: ${getCurrencyFormat(
              otherTotalCost || 0,
              appInfo.currency
            )}`,
            liteOtherColumns(setEditModal, setDocumentModal),
            formattedResultOther
          )}
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimCostsSectionDefault;
