/* -------------------------------------------------------------------------- */
/*                                    Enums                                   */
/* -------------------------------------------------------------------------- */
export enum Config_ClaimsHoldingForms {
  BASE = 'BASE',
  TYPE1 = 'TYPE1',
}

export enum Config_ClaimsTableType {
  SIMPLE = 'SIMPLE',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
}

export enum Config_ClaimDetailViewType {
  DEFAULT = 'DEFAULT',
  CAR_DETAILS = 'CAR_DETAILS',
}

export enum Config_ClaimDetailsSectionType {
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
}

export enum Config_UpdateClaimDetails_CustomerFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
}

export enum Config_UpdateClaimDetails_ClaimDetailsFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export enum Config_UpdateClaimDetails_AttachmentsFormType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export enum Config_ClaimActionsType {
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
}

export enum Config_RegistrationDetailsView_WarrantyDetailsSectionType {
  DEFAULT = 'DEFAULT',
  CAR_WARRANTY = 'CAR_WARRANTY',
  STONE_WARRANTY = 'STONE_WARRANTY',
}

export enum Config_ClaimCosts_LogType {
  DEFAULT = 'DEFAULT',
  TYPE1 = 'TYPE1',
}

export const enum Config_RegistrationTableType {
  DEFAULT = 'DEFAULT',
  BIKE_TYPE = 'BIKE_TYPE',
}

/* -------------------------------------------------------------------------- */
/*                                    Types                                   */
/* -------------------------------------------------------------------------- */
export type RestrictedUserPathsType = {
  Warrantor: string[];
  ExternalRepairer: string[];
};
