import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  CAR_FUEL_OPTIONS,
  CAR_TRANSMISSION_OPTIONS,
} from '@rabbit/bizproc/react';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';

export const validationSchema = Yup.object().shape({
  registrationNo: Yup.string()
    .trim()
    .required('Please enter registration plate'),
  cherishedRegistrationNo: Yup.string().trim(),
  make: Yup.string().trim().required('Please enter make name'),
  model: Yup.string().trim().required('Please enter model name'),
  version: Yup.string(),
  engineCc: Yup.string().trim().required('Please enter engine cc'),
  fuel: Yup.string().trim().required('Please select fuel option'),
  transmission: Yup.string()
    .trim()
    .required('Please select transmission option'),
  yearOfManufacture: Yup.string()
    .trim()
    .required('Please enter year of manufacture'),
  regDate: Yup.string().trim().nullable().required('Please enter registration date'),
  chassisNo: Yup.string().trim().required('Please enter vin'),
  mileage: Yup.object({
    addon: Yup.string().required('Please select the mileage unit'),
    value: Yup.string().trim().required('Please enter mileage'),
  }).required('Please enter mileage'),
  last_service_date: Yup.string()
    .trim()
    .required('Please enter last service date mileage'),
  tech_check_date: Yup.string(),
  purchase_price: Yup.mixed(),
});

export interface CreateNewRegistrationFormProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape & { mileageUnit: string };
}

interface CarRegistrationShape {
  registrationNo: string;
  cherishedRegistrationNo: string;
  make: string;
  model: string;
  version: string;
  engineCc: string;
  fuel: string;
  transmission: string;
  yearOfManufacture: string;
  regDate: string;
  chassisNo: string;
  mileage: {
    addon: string;
    value: string;
  };
  last_service_date: string;
  tech_check_date: string;
  purchase_price: {
    amount: string;
    currency: string;
  };
  manualInput: boolean;
}

export function CreateNewRegistration(props: CreateNewRegistrationFormProps) {
  const { onChange, handleClose, data } = props;
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const mileageUnit = t('CFG_COBRAND_MILEAGE_UNIT');
  const onSubmit = (values: any) => {
    const result = {
      ...values,
      mileageUnit: values.mileage.addon,
      mileage: values.mileage.value,
      manualInput: data.manualInput || false,
    };
    onChange(2, result);
  };

  const initialValues: CarRegistrationShape = {
    ...data,
    cherishedRegistrationNo: data.cherishedRegistrationNo || '',
    purchase_price: {
      currency: appInfo.currency,
      amount: data.purchase_price?.amount || '',
    },
    mileage: {
      addon: data.mileageUnit || mileageUnit,
      value: (data.mileage as any) || '',
    },
    manualInput: data.manualInput || false,
  };

  const yearOptions: any[] = Array(new Date().getFullYear() - 1940)
    .fill('')
    .map((_: any, i: number) => ({
      id: `${i + 1}`,
      label: `${1940 + i + 1}`,
      value: 1940 + i + 1,
    }))
    .reverse();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => {
        return (
          <Form className="flex-col gap-3 px-5 pt-5">
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="registrationNo"
                label={t('general.registrationPlate')+'*'}
                settings={{
                  id: 'registrationNo',
                  placeholder: 'Enter registration plate',
                  disabled:
                    data.registrationNo !== undefined &&
                    !data.manualInput &&
                    data.registrationNo !== '',
                }}
              />
              <Input
                type="text"
                name="cherishedRegistrationNo"
                label={t('general.cherishedRegistrationPlate')}
                settings={{
                  id: 'cherishedRegistrationNo',
                  placeholder: 'Enter cherished registration plate',
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="make"
                label={t('general.make')+'*'}
                settings={{
                  id: 'make',
                  placeholder: 'Enter make name here',
                  disabled:
                    data.make !== undefined &&
                    !data.manualInput &&
                    data.make !== '',
                }}
              />
              <Input
                type="text"
                name="model"
                label={t('general.model')+'*'}
                settings={{
                  id: 'model',
                  placeholder: 'Enter model name here',
                  disabled:
                    data.model !== undefined &&
                    data.model !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="version"
                label={t('general.version')}
                settings={{
                  id: 'version',
                  placeholder: 'Enter version name here',
                  disabled:
                    data.version !== undefined &&
                    data.version !== '' &&
                    !data.manualInput,
                  allowSpecialCharacter: true,
                }}
              />
              <Input
                type="text"
                name="engineCc"
                label={t('general.engineCc')+'*'}
                settings={{
                  id: 'engineCc',
                  placeholder: 'Enter engine cc here',
                  disabled:
                    data.engineCc !== undefined &&
                    data.engineCc !== '' &&
                    !data.manualInput,
                  type: 'number',
                }}
              />
            </div>
            <div className="mt-4 flex items-start gap-4">
              <Input
                type="select"
                name="fuel"
                label={t('general.fuel')+'*'}
                settings={{
                  id: 'fuel',
                  placeholder: 'Please select an option',
                  options: CAR_FUEL_OPTIONS,
                  errors: props.errors,
                  disabled:
                    data.fuel !== undefined &&
                    data.fuel !== '' &&
                    !data.manualInput,
                }}
              />
              <Input
                type="select"
                name="transmission"
                label={t('general.transmission')+'*'}
                settings={{
                  id: 'transmission',
                  placeholder: 'Please select an option',
                  options: CAR_TRANSMISSION_OPTIONS,
                  errors: props.errors,
                  disabled:
                    data.transmission !== undefined &&
                    data.transmission !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="select"
                name="yearOfManufacture"
                label={t('general.yearOfManufacture')+'*'}
                settings={{
                  id: 'yearOfManufacture',
                  placeholder: 'YYYY',
                  options: yearOptions,
                  disabled:
                  data.yearOfManufacture !== undefined &&
                  data.yearOfManufacture !== '' &&
                  !data.manualInput,
                }}
                />
              <Input
                type="datepicker"
                name="regDate"
                label={t('general.registrationDate')+'*'}
                settings={{
                  id: 'regDate',
                  placeholder: 'DD/MM/YYYY',
                  maxDate: new Date(),
                  disabled:
                    data.regDate !== undefined &&
                    data.regDate !== '' &&
                    !data.manualInput,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="text"
                name="chassisNo"
                label={t('general.vin')+'*'}
                settings={{
                  id: 'chassisNo',
                  placeholder: 'Enter vin here',
                  disabled:
                    data.chassisNo !== undefined &&
                    data.chassisNo !== '' &&
                    !data.manualInput,
                }}
              />
              <Input
                type="selectAddonText"
                name="mileage"
                label={t('general.carMileage')+'*'}
                settings={{
                  id: 'mileage',
                  addonOptions: [
                    { id: '0', label: 'km', value: 'km' },
                    { id: '1', label: 'mi', value: 'mi' },
                  ],
                  placeholder: 'Enter make car mileage',
                  type: 'number',
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="datepicker"
                name="last_service_date"
                label={t('general.lastServiceDate')+'*'}
                settings={{
                  id: 'last_service_date',
                  placeholder: 'DD/MM/YYYY',
                  maxDate: new Date(),
                }}
              />
              <Input
                type="datepicker"
                name="tech_check_date"
                label={t('general.nctDateExpiration')}
                settings={{
                  id: 'tech_check_date',
                  placeholder: 'Enter nct date expiration here',
                  disabled: data.tech_check_date ? true : false,
                }}
              />
            </div>
            <div className="mt-4 flex gap-4">
              <Input
                type="currency"
                name="purchase_price"
                label={t('general.purchasePrice')}
                settings={{
                  id: 'purchase_price',
                  placeholder: 'Enter purchase price here',
                  currency:
                    props.values?.purchase_price?.currency ?? appInfo.currency,
                }}
              />
            </div>
            <div className="pt-5 flex w-full gap-4 sticky bottom-0 bg-white">
              <Button
                kind="primary"
                type="submit"
                disabled={Object.keys(props.errors).length > 0}
              >
                Continue
              </Button>
              <Button kind="red" type="submit" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CreateNewRegistration;
