import { calcWarrantyTimeLeft } from '@rabbit/bizproc/react';
import { ConfigContext } from '@rabbit/config/context';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  getClaimLimit,
  getIncreasedLabourRate,
  getIncreasedLabourRateValue,
  getWarrantyTerm,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  renderWarrantyRemaining,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function ClaimWarrantyDetailSection() {
  const context = useContext(CaseflowContext);
  const { config } = useContext(ConfigContext);
  const { holdingData } = context || {};
  const { t } = useTranslation();
  const tanantLink = t('tenantLink');
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';

  const warrantyInfo = holdingData?.warranties?.[0];
  const appInfo = useAppInfo();
  const remaining: { amount: number | null; unit: string } | undefined =
    warrantyInfo?.endDate && warrantyInfo?.startDate
      ? calcWarrantyTimeLeft(warrantyInfo?.endDate, warrantyInfo?.startDate)
      : undefined;
  // const showVAT = t('CFG_COBRAND_REGISTRATIONS_SHOW_VAT', {
  //   defaultValue: false,
  // }) as unknown as boolean;
  const labourRateValue = getIncreasedLabourRate(
    warrantyInfo,
    appInfo.currency
  );

  const record: LabelledItemShape[] = [
    {
      label: 'Warranty ID',
      // label: t('general.warrantyId'),
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase() || '-',
    },
    {
      label: 'Warranty plan',
      value: warrantyInfo?.templateTitle || '-',
    },
    {
      label: 'Registration date',
      value:
        holdingData?.register_time || holdingData?.purchase_time
          ? formatUnixTime(
              holdingData?.register_time || holdingData?.purchase_time,
              'dd/MM/yyyy'
            )
          : '-',
    },
    {
      label: 'Warranty term',
      value: holdingData ? `${getWarrantyTerm(holdingData)}` : '-',
    },
    {
      label: 'Start date',
      value: warrantyInfo?.startDate
        ? formatUnixTime(warrantyInfo?.startDate, 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Expiry date',
      value: warrantyInfo?.endDate
        ? formatUnixTime(warrantyInfo?.endDate, 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Warranty remaining',
      value: remaining
        ? renderWarrantyRemaining(holdingData as any, false)
        : '-',
    },
    {
      label: 'Warranty expired',
      value: warrantyInfo?.endDate
        ? warrantyInfo?.endDate < Date.now()
          ? 'Yes'
          : 'No'
        : '-',
    },
    {
      label: 'Extended warranty',
      value: 'Yes',
    },
  ];

  // TODO: Implement car tenants fields
  if (tanantLink === 'PINNACLEWARRANTIES') {
    record.push(
      ...[
        {
          label: 'Claim limit',
          value: warrantyInfo
            ? getClaimLimit(
                warrantyInfo,
                appInfo.currency,
                CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT
              )
            : '-',
        },
        {
          label: 'Labour rate',
          value:
            Array.isArray(labourRateValue) && labourRateValue.length > 0
              ? (getIncreasedLabourRate(warrantyInfo, appInfo.currency) as any)
              : '-',
        },
      ]
    );
  }

  if (config.CLAIMS.CLAIM_DETAIL_VIEW.TYPE === 'CAR_DETAILS') {
    record.push({
      label: 'Partner',
      value: warrantyInfo?.retailerName || '-',
    });
  }

  return (
    <CardWrapperWithHeader title="Warranty details">
      <LabelledItemList
        items={record}
        listWrapperClasses="font-nunito grid grid-cols-3 gap-y-6 gap-x-4"
      />
    </CardWrapperWithHeader>
  );
}
