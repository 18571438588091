import { useContext } from 'react';
import {
  formatUnixTime,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { useTranslation } from 'react-i18next';
import { stripTags } from '@rabbit/sage/utils/helpers';

export interface ClaimDetailsSectionType2Props {
  renderFns: {
    renderCaseFiles: () => JSX.Element;
    renderShopifyOrderLink: () => JSX.Element;
    renderSerialProof: () => JSX.Element;
    renderConsumerIssueDesc: () => JSX.Element;
    renderClaimDetailItemsLine: (
      claimDetailItems: LabelledItemShape[],
      keys: string[],
      extraClasses?: string
    ) => JSX.Element;
  };
  faultLabels: string[];
}

export function ClaimDetailsSectionType2(props: ClaimDetailsSectionType2Props) {
  const { faultLabels } = props;
  const {
    renderCaseFiles,
    renderShopifyOrderLink,
    renderSerialProof,
    renderClaimDetailItemsLine,
  } = props.renderFns;
  const { caseFacts, holdingData, holdingProxyData, caseId, caseTimeCreated } =
    useContext(CaseflowContext) || {};

  const { t } = useTranslation();
  // const { config } = useContext(ConfigContext);
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holdingProxyData?.warranties
  );

  const isWithinWarranty = () => {
    if (!holdingProxyData) return '-';

    if (!latestWarranty || !latestWarranty.endDate) return '-';

    return OurDateTime.nowUTCTimestamp() < latestWarranty?.endDate
      ? 'Yes'
      : 'No';
  };

  /* --------------------------- Claim detail items --------------------------- */

  const claimDetailItems: LabelledItemShape[] = [
    {
      key: 'claim_id',
      label: t('Claim ID'),
      value: caseId ?? '-',
    },
    {
      key: 'date_of_claim',
      label: t('Date of claim'),
      value: caseTimeCreated
        ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
        : '-',
    },
    {
      key: 'purchase_date',
      label: t('Purchase date'),
      value:
        holdingData?.purchase_time && holdingData?.purchase_time > 0
          ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'warranty_term',
      label: t('Warranty term'),
      value: holdingData?.warranty_term?.amount
        ? `${holdingData?.warranty_term?.amount} ${holdingData?.warranty_term?.division}`
        : '-',
    },
    {
      key: 'warranty_expiry',
      label: t('Warranty expiry'),
      value:
        latestWarranty?.endDate && latestWarranty?.endDate > 0
          ? formatUnixTime(latestWarranty?.endDate, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'within_warranty_period',
      label: t('Within warranty period'),
      value: isWithinWarranty(),
    },
    {
      key: 'customer_issue_type',
      label: t('Customer issue type'),
      value: caseFacts?.consumer_issue_type ?? '-',
    },
    {
      key: 'customer_issue_description',
      label: t('Customer issue description'),
      value: stripTags(caseFacts?.consumer_issue_description ?? '') ?? '-',
    },
    {
      key: 'proof_of_purchase',
      label: t('Proof of purchase / Supporting materials'),
      value: renderCaseFiles(),
    },
    {
      key: 'shopify_order',
      label: t('Shopify order'),
      value: renderShopifyOrderLink(),
    },
    {
      key: 'serial_number',
      label: t('Serial number'),
      value: caseFacts?.consumer_holding_serial_number
        ? caseFacts.consumer_holding_serial_number
        : caseFacts?.serial_number
        ? caseFacts.serial_number
        : '-',
    },
    {
      key: 'serial_number_proof',
      label: t('Serial number proof'),
      value: renderSerialProof(),
    },
  ];

  /* -------------------------------- Main TSX -------------------------------- */
  return (
    <div>
      {renderClaimDetailItemsLine(claimDetailItems, [
        'claim_id',
        'date_of_claim',
        'purchase_date',
        'warranty_term',
        'warranty_expiry',
      ])}
      {renderClaimDetailItemsLine(claimDetailItems, [
        'customer_issue_type',
        'customer_issue_description',
        'within_warranty_period',
        'proof_of_purchase',
        'shopify_order',
      ])}
      {renderClaimDetailItemsLine(
        claimDetailItems,
        ['serial_number', 'serial_number_proof'],
        'mb-0'
      )}
    </div>
  );
}

export default ClaimDetailsSectionType2;
