import { TrashIcon } from '@heroicons/react/24/solid';
import {
  ConsumerIssueTypeHookOrigins,
  useManageConsumerIssueTypes,
} from '@rabbit/bizproc/react';
import { DTConsumerIssueType } from '@rabbit/data/types';
import { Button, Input } from '@rabbit/elements/shared-components';
import { Formik } from 'formik';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export interface ModalAddEditIssueTypeProps {
  handleClose: () => void;
  // Note: HandleSubmit is probably not necessary here - review
  handleSubmit?: () => void;
  // Note: same with handleDelete?
  handleDelete?: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  itemDetails?: DTConsumerIssueType;
  mode: 'edit' | 'new';
}

interface FormDataShape {
  label: string;
  enabled: boolean;
  description?: string;
  orderNo?: number;
}

const schema = Yup.object({
  label: Yup.string().trim().required("Fault name can't be empty"),
  enabled: Yup.boolean().required('Required'),
  description: Yup.string().trim(),
  // orderNo: Yup.number(), // todo
});

export function ModalAddEditIssueType({
  handleClose,
  itemDetails,
  mode,
  setLoading,
}: ModalAddEditIssueTypeProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    consumerIssueTypeList,
    createConsumerIssueType,
    editConsumerIssueType,
    consumerIssueTypeData,
  } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE_MANAGE,
    itemDetails?.docid
  );

  const initialValues: FormDataShape = {
    label: itemDetails ? itemDetails.label : '',
    enabled: itemDetails ? itemDetails.enabled : true,
    description: itemDetails ? itemDetails.description : '',
  };

  const handleSubmit = async (values: FormDataShape) => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      if (mode === 'edit') {
        await editConsumerIssueType({
          label: values.label ?? '',
          enabled: values.enabled,
          description: values.description ?? '',
          // orderNo: values.orderNo, // todo
        });
      } else if (mode === 'new') {
        await createConsumerIssueType({
          label: values.label ?? '',
          enabled: values.enabled,
          description: values.description ?? '',
          // orderNo: values.orderNo, // todo
        });
      } else {
        throw new Error('Invalid mode');
      }
      toast.success('Issue type saved successfully.');
    } catch (error) {
      console.log(error);
      toast.error('Error saving issue type');
    } finally {
      setIsSubmitting(false);
      setLoading(false);
      handleClose();
    }
  };

  // No deleting allowed, for now at least
  // const handleDelete = async () => {
  //   setIsSubmitting(true);
  //   setLoading(true);
  //   try {
  //     await disableConsumerIssueType();
  //     toast.success('Fault deleted successfully.');
  //     handleClose();
  //   } catch (err) {
  //     console.log(err);
  //     toast.error('Error deleting fault');
  //   } finally {
  //     setIsSubmitting(false);
  //     setLoading(false);
  //     handleClose();
  //   }
  // };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <form>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="text"
                  label="Label*"
                  name="label"
                  settings={{
                    placeholder: t('Issue type name'),
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="checkbox"
                  label="Enabled*"
                  name="enabled"
                  settings={{
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="py-2 px-5">
              <Input
                type="textarea"
                label="Description"
                name="description"
                settings={{ placeholder: 'Write text here ...' }}
              />
            </div>
            <div className="flex gap-5 py-4 px-5">
              <Button
                kind="primary"
                size="md"
                children={mode === 'edit' ? 'Save all' : 'Save'}
                disabled={isSubmitting}
                onClick={props.handleSubmit}
              />
              {/* No deleting allowed. For now at least */}
              {/* {mode === 'edit' && (
                <Button
                  kind="red"
                  size="md"
                  icon={<TrashIcon />}
                  children="Delete fault"
                  disabled={isSubmitting}
                  onClick={handleDelete}
                />
              )} */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
