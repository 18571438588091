import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  CLAIM_STATUS_OPTIONS_LITE,
  COVERAGE_OPTIONS,
  LIST_COUNTRIES,
  ReactCaseFlowCase,
} from '@rabbit/bizproc/react';
import ClaimDetailEditor from '../../../../../../utils/ClaimDetailEditor';
import { useTranslation } from 'react-i18next';
import { OurDateTime } from '@rabbit/utils/ts';
import { SharedValuesShape } from '../../../../ModalEditClaimDetails/ModalEditClaimDetails';
import { ConfigContext } from '@rabbit/config/context';
import { CaseflowCaseTypes } from '@rabbit/bizproc/core';

export interface UpdateClaimDetailsFormProps {
  claimDetails: any; // todo type
  handleClose: () => void;
  caseFlowCase: ReactCaseFlowCase;
  setLoading: Dispatch<SetStateAction<boolean>>;
  sharedValues: SharedValuesShape;
  setSharedValues: Dispatch<SetStateAction<SharedValuesShape>>;
  vendableLabel: string;
  locationLabel: string;
  faultOptions: {
    id: string;
    value: string;
    label: string;
    name: string;
  }[];
  consumerIssueTypeOptions: {
    id: string;
    value: string;
    label: string;
  }[];
}

export const validationSchema = Yup.object().shape({
  holding_vendable_id: Yup.string().trim(),
  consumer_holding_purchase_location: Yup.mixed(),
  store_not_listed: Yup.boolean(),
  consumer_holding_purchase_price: Yup.mixed(),
  consumer_holding_purchase_country: Yup.string().trim(),
  purchase_date: Yup.mixed(),
  status: Yup.string(),
  holding_warranty_term: Yup.number(),
  holding_faults: Yup.array(),
  preliminary_assessment: Yup.string().trim(),
  consumer_issue_description: Yup.string().trim(),
  consumer_issue_type_ref: Yup.string().trim(),
});

export function UpdateClaimDetailsFormDefault({
  claimDetails,
  handleClose,
  caseFlowCase,
  setLoading,
  setSharedValues,
  sharedValues,
  vendableLabel,
  locationLabel,
  faultOptions,
  consumerIssueTypeOptions,
}: UpdateClaimDetailsFormProps) {
  // const appInfo = useAppInfo();
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';

  const { config } = useContext(ConfigContext);
  // const { operatingPersonaSingleKey } =
  //   useContext(CaseflowContext) || ({} as CaseflowInterface);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateSubmitting = (state: boolean) => {
    setIsSubmitting(state);
    setLoading(state);
  };

  const initialValues = sharedValues.claimDetails || {
    holding_vendable_id:
      (claimDetails && claimDetails.holding_vendable_id) || '',
    consumer_holding_purchase_location: null,
    purchase_date: claimDetails?.purchase_date
      ? OurDateTime.timestampToDate(claimDetails?.purchase_date)
      : 0,
    holding_warranty_term:
      claimDetails?.holding_warranty_term?.amount ?? undefined,
    preliminary_assessment:
      (claimDetails && claimDetails.preliminary_assessment) || '',
    // fault_category: claimDetails?.fault_category ?? '',  // disabled until fault categories are implemented
    holding_faults: claimDetails?.holding_faults ?? [],
    status: caseFlowCase.GetCaseState(),
    consumer_holding_purchase_price: {
      amount: claimDetails?.consumer_holding_purchase_price?.amount || '',
      currency: claimDetails?.consumer_holding_purchase_price?.currency || '',
    },
    consumer_issue_description: claimDetails?.consumer_issue_description || '',
    consumer_issue_type_ref: claimDetails?.consumer_issue_type_ref || '',
    store_not_listed: claimDetails.consumer_holding_purchase_location_other
      ? true
      : false,
    consumer_holding_purchase_country:
      claimDetails.consumer_holding_purchase_country || '',
    consumer_holding_purchase_location_other:
      claimDetails.consumer_holding_purchase_location_other ?? '',
  };

  const onSubmit = () => {
    updateSubmitting(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors }) => {
        setSharedValues((value) => {
          value.claimDetails = values;
          return value;
        });
        return (
          <Form>
            <div className="flex flex-col gap-4">
              {/* todo clone into a type2 variation eventually to avoid this condition*/}
              {config.CLAIMS.CASEFLOW_TYPE !== CaseflowCaseTypes.SHELTA && (
                <>
                  <Input
                    type="select"
                    label={t('Claim status')}
                    name="status"
                    settings={{
                      options: CLAIM_STATUS_OPTIONS_LITE,
                      id: 'status',
                      placeholder: t('Please select an option'),
                    }}
                  />
                  <hr className="my-4 border-t border-gray-200" />
                </>
              )}
              <div className="flex items-start gap-4">
                <div className="w-1/3 shrink-0 grow">
                  <Input
                    type="autocomplete-vendable"
                    name="holding_vendable_id"
                    label="Product"
                    settings={{
                      isMulti: false,
                      id: 'holding_vendable_id',
                      placeholder: vendableLabel,
                      options: [],
                      errors: errors,
                      tenantLink: t('tenantLink'),
                      disabled: true,
                    }}
                  />
                </div>
                {!claimsFlowLite && (
                  // location and store not visible for lite flow -VP
                  <div className="flex w-1/3 shrink-0 grow flex-col">
                    <Input
                      type="autocomplete-location-retailer"
                      name="consumer_holding_purchase_location"
                      label="Purchase location"
                      settings={{
                        id: 'consumer_holding_purchase_location',
                        placeholder: locationLabel,
                        options: [],
                        isMulti: false,
                        errors: errors,
                        disabled: values.store_not_listed,
                      }}
                    />
                    <Input
                      type="checkbox"
                      name="store_not_listed"
                      settings={{
                        checkboxLabel: 'Store is not listed',
                        checkboxLabelStyles: 'text-base text-gray-500',
                      }}
                    />
                  </div>
                )}
              </div>
              {values.store_not_listed && !claimsFlowLite && (
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="text"
                    label="Purchase location"
                    name="consumer_holding_purchase_location_other"
                    settings={{
                      id: 'consumer_holding_purchase_location_other',
                      placeholder: 'Enter store name',
                    }}
                  />
                  <Input
                    type="select"
                    label="Purchase country"
                    name="consumer_holding_purchase_country"
                    settings={{
                      options: LIST_COUNTRIES,
                      id: 'consumer_holding_purchase_country',
                      placeholder:
                        'The country where the product was purchased',
                    }}
                  />
                </div>
              )}
              <div className="flex items-end gap-4">
                <Input
                  type="datepicker"
                  name="purchase_date"
                  label="Date of purchase"
                  settings={{
                    id: 'purchase_date',
                    maxDate: new Date(),
                    placeholder: 'Date of purchase',
                    disabled: true,
                  }}
                />
                <Input
                  type="warranty_term"
                  name="holding_warranty_term"
                  label="Warranty term"
                  settings={{
                    id: 'holding_warranty_term',
                    placeholder: '',
                    disabled: true,
                  }}
                />
              </div>
              <hr className="my-4 border-t border-gray-200" />
              <div className="flex items-start gap-4">
                {/*
              // disabled until fault categories are implemented
              <Input
                type="select"
                label="Fault category"
                name="fault_category"
                settings={{
                  options: FAULT_TYPE,
                  placeholder: 'Product defect',
                  id: 'fault_category',
                  errors: errors,
                }}
              /> */}
                {/*<Input
                  type="select"
                  label="Faults"
                  name="holding_faults"
                  settings={{
                    options: faultOptions,
                    isMulti: true,
                    id: 'holding_faults',
                    errors: errors,
                  }}
                />*/}
              </div>
              <div className="grid grid-cols-2 items-end gap-4">
                <Input
                  type="select"
                  label="Warranty assessment"
                  name="preliminary_assessment"
                  settings={{
                    options: COVERAGE_OPTIONS,
                    placeholder: 'Please select an option',
                    id: 'preliminary_assessment',
                    errors: errors,
                  }}
                />
                {/*<Input
                type="currency"
                name="consumer_holding_purchase_price"
                label="Purchase price"
                settings={{
                  id: 'consumer_holding_purchase_price',
                  placeholder: 'Purchase amount',
                  currency:
                    values?.consumer_holding_purchase_price?.currency ??
                    appInfo.currency,
                }}
                />*/}
              </div>
              <div className="flex items-start">
                <Input
                  type="select"
                  name="consumer_issue_type_ref"
                  label="Customer issue type"
                  settings={{
                    isMulti: false,
                    id: 'consumer_issue_type_ref',
                    placeholder: 'Please select',
                    options: consumerIssueTypeOptions,
                    errors: errors,
                  }}
                />
              </div>
              <div className="flex w-full flex-col">
                <Input
                  type="rich-text"
                  name="consumer_issue_description"
                  label="Customer issue description"
                  settings={{
                    id: 'consumer_issue_description',
                    placeholder: 'Write text here...',
                    allowSpecialCharacter: true,
                    disabled: claimDetails?.consumer_issue_description
                      ? true
                      : false,
                  }}
                />
              </div>
              <div className="flex gap-4">
                <Button
                  kind="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={errors ? false : true}
                  className="bg-primary-600"
                >
                  Update claim
                </Button>
                <Button
                  kind="red"
                  type="button"
                  loading={isSubmitting}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
            {isSubmitting && caseFlowCase && (
              <ClaimDetailEditor
                caseFlowCase={caseFlowCase}
                target={'claim_details'}
                formData={{
                  ...values,
                  consumer_holding_purchase_location:
                    values.consumer_holding_purchase_location ?? null,
                  consumer_issue_type:
                    consumerIssueTypeOptions.find(
                      (option) => option.id === values.consumer_issue_type_ref
                    )?.label ?? '',
                  purchase_date:
                    values.purchase_date &&
                    typeof values.purchase_date !== 'number'
                      ? OurDateTime.dateToTimestamp(values.purchase_date)
                      : 0,
                }}
                setIsSubmitting={updateSubmitting}
                onComplete={() => {
                  handleClose();
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default UpdateClaimDetailsFormDefault;
