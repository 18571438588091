import { SetStateAction, useContext, useEffect, useState } from 'react';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { DTWarranty_Template, VendableFull } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { formatAndGetLastCategory } from '@rabbit/sage/utils/helpers';
import { VendableEditorContext } from '@rabbit/bizproc/react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VendableDetailsTemplateProps {
  vendableId: string;
  setIsEditProductModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

export function VendableDetailsTemplate({
  vendableId,
  setIsEditProductModalOpen,
}: VendableDetailsTemplateProps) {
  const { t } = useTranslation();
  const [vendableData, setVendableData] = useState<VendableFull | null>(null);
  const [warrantyTemplate, setWarrantyTemplate] =
    useState<DTWarranty_Template | null>(null);

  const { getSingleVendable, getSingleWarrantyTemplate } =
    useContext(VendableEditorContext) || {};

  /* ------------------------------- Fetch data ------------------------------- */
  useEffect(() => {
    if (!vendableId || !getSingleVendable || !getSingleWarrantyTemplate) return;

    getSingleVendable(vendableId).then((data: VendableFull | null) => {
      setVendableData(data);
      if (data && data.pub?.defaultTemplate)
        getSingleWarrantyTemplate(data?.pub?.defaultTemplate).then((data) => {
          setWarrantyTemplate(data);
        });
    });
  }, [vendableId]);

  if (!vendableData || !warrantyTemplate)
    return <LoadingSpinner size="md" extraClasses="mt-16" />;

  const items: LabelledItemShape[] = [
    {
      key: 'status',
      label: t('Status'),
      value: vendableData?.pub.published ? (
        <div className="inline overflow-hidden rounded bg-green-100 py-1 px-2">
          {t('Published')}
        </div>
      ) : (
        <div className="inline overflow-hidden rounded bg-gray-100 py-1 px-2">
          {t('Unpublished')}
        </div>
      ),
    },
    {
      key: 'product_name',
      label: t('Product name'),
      value: vendableData?.pub.full,
    },
    {
      key: 'brand',
      label: t('Brand'),
      value: vendableData?.pub.brand,
    },
    {
      key: 'category',
      label: t('Category'),
      value: vendableData?.pub?.category
        ? formatAndGetLastCategory(vendableData?.pub?.category)
        : '-',
    },
    {
      key: 'sku',
      label: t('SKU'),
      value: vendableData?.pub.mpn,
    },
    {
      key: 'upc',
      label: t('UPC'),
      value: vendableData?.pub.upc,
    },
    {
      key: 'warranty_plan',
      label: t('Warranty plan'),
      value: warrantyTemplate?.title,
    },
    {
      key: 'price',
      label: t('Price'),
      // todo improve
      value: vendableData?.priv.rrp?.amount
        ? `${vendableData?.priv.rrp?.currency} ${vendableData?.priv.rrp?.amount}`
        : '-',
    },
    {
      key: 'deprecated',
      label: t('Deprecated'),
      value: vendableData?.pub.deprecated ? t('Yes') : t('No'),
    },
    {
      key: 'search_keywords',
      label: t('Search keywords'),
      value: vendableData?.pub.keywords?.length
        ? vendableData?.pub.keywords?.join(', ')
        : '-',
    },
    // todo improve for multiple manuals
    {
      key: 'user_manual',
      label: t('User manual'),
      value: vendableData?.pub.manual?.[0]?.url ? (
        <a
          href={vendableData.pub.manual[0].url}
          className="font-nunito text-base underline"
        >
          {t('Link')}
        </a>
      ) : (
        <div className="overflow-hidden text-ellipsis font-medium">-</div>
      ),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                                     TSX                                    */
  /* -------------------------------------------------------------------------- */

  return (
    <div className="flex flex-col gap-5">
      <div className="flex w-full items-start justify-between gap-4 rounded-md bg-gray-100 p-4">
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <img
              src={vendableData?.pub.img?.[0] ?? ''}
              alt="productImg"
              className="h-20 w-20 rounded-lg"
            />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="font-caudex w-full overflow-hidden text-ellipsis text-xl">
              {vendableData?.pub.full}
            </p>
            <p>
              {vendableData?.pub.category &&
                formatAndGetLastCategory(vendableData?.pub.category)}
            </p>
          </div>
        </div>
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
          </div>
          {/* todo warranty / category bit*/}
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              {warrantyTemplate.title}
            </p>
            {/* Leaving this here in case we add in a subtitle */}
            {/* <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
              Shade - 1 year
            </p> */}
          </div>
        </div>
      </div>

      <CardWrapperWithHeader
        title={t('Product information')}
        headerRight={
          <ButtonIcon
            iconLeft={true}
            label={t('Edit product')}
            kind={'bgLightGreen'}
            onClick={() => setIsEditProductModalOpen(true)}
            Icon={ArrowTopRightOnSquareIcon}
          />
        }
      >
        <LabelledItemList items={items} />
      </CardWrapperWithHeader>
    </div>
  );
}

export default VendableDetailsTemplate;

/* -------------------------------------------------------------------------- */
/*                                 Unused Code                                */
/* -------------------------------------------------------------------------- */
// ui for shoplifter vendables
{
  /* <div className="border border-gray-200 rounded-lg p-2 flex flex-col gap-4">
        <div className="flex gap-2">
          <div className="flex justify-start gap-4 grow">
            <div>
              <img src={mockImage1} alt="productImg" className="w-20 h-20 rounded-lg" />
            </div>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex gap-2 flex-col">
                <div className="text-base">{vendableData.full}</div>
                <div className="flex gap-1">
                  <div className="bg-green-100 py-1 px-2 rounded overflow-hidden inline green-100">Imported</div> 
                  <div className="bg-red-100 py-1 px-2 rounded overflow-hidden inline">Hidden</div>
                  </div>
              </div>
            </div>
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl font-caudex">
              {vendableData.full}
            </p>
            <p>
              {vendableData.category && formatAndGetLastCategory(vendableData.category)}
            </p>
          </div>
        </div>
        <div className="flex w-1/3 grow items-start gap-3">
          <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
            <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
          </div>
          <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
            <p className="w-full overflow-hidden text-ellipsis text-xl">
              1 year warranty
            </p>
            <p className="text-base opacity-50 text-ellipsis overflow-hidden w-full">
              Shade - 1 year
            </p>
          </div>
        </div>
      </div>
      {/* <div className="border border-gray-200 rounded-lg p-2 flex flex-col gap-4">
        <div className="flex gap-2">
          <div className="flex justify-start gap-4 grow">
            <div>
              <img src={mockImage1} alt="productImg" className="w-20 h-20 rounded-lg" />
            </div>
            <div className="flex flex-col gap-4 py-4">
              <div className="flex gap-2 flex-col">
                <div className="text-base">{vendableData.full}</div>
                <div className="flex gap-1">
                  <div className="bg-green-100 py-1 px-2 rounded overflow-hidden inline green-100">Imported</div> 
                  <div className="bg-red-100 py-1 px-2 rounded overflow-hidden inline">Hidden</div>
                  </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <ButtonIcon
                kind="bgLightGreen"
                onClick={() => console.log('clicked syncronize')}
                label="Syncronize"
                Icon={ArrowPathIcon}
                iconLeft
              />
          </div>
        </div>
        <div className="w-full flex gap-2 bg-gray-100 border border-gray-200 px-3 py-2 rounded-lg items-center">
          <div className="grow">Show on search</div>
          <Toggle name="search" checked={false} onChange={function (): void {
            throw new Error('Function not implemented.');
          } } />
        </div>
      </div> */
}
