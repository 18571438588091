import React, { useState, useEffect, useContext } from 'react';
import {
  ButtonIconVertical,
  CardWrapperWithHeader,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  AssignCaseInternalModal,
  DetermineCoverageModal,
  FinalAssessmentModal,
  ApproveCaseForRepairModal,
  AssignClaimToThirdPartyModal,
  CompleteRepairModal,
  ConfirmItemReceivedModal,
  ContactCustomerModal,
  LogItemReceivedModal,
  LogPostageModal,
  LogRepairCompleteModal,
  LogRepairWorkModal,
  QuoteClaimModal,
  RequestToSendItemModal,
  ReplacePartsOrProductModal,
  LocalRepairModal,
  CloseCaseModal,
  CustomerCollectionModal,
  RejectClaimModal,
} from '../../../../organisms/case-flow-modals';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { getActionIcon } from '../../../../../utils/consts';
import { PossibleActionEntry } from '@rabbit/data/types';
import CompleteRepairAndCloseModal from '../../../../organisms/case-flow-modals/CompleteRepairAndCloseModal/CompleteRepairAndCloseModal';
import { CaseFactsShape, CaseFlowCaseStations } from '@rabbit/bizproc/core';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimActionsType1Props {
  filterAvailableCFCActions: (
    station: string,
    condition: string,
    CASEFLOW_CONDITIONAL_ACTIONS: any
  ) => { [key: string]: any } | null;
}

export function ClaimActionsType1(props: ClaimActionsType1Props) {
  const { filterAvailableCFCActions } = props;
  const {
    caseFlowCase,
    setShowModal,
    setModalSettings,
    caseState,
    caseActionsStation,
    caseActionsAll,
    executeAction,
    alterCaseFacts,
    caseFacts,
    caseId,
    caseAlterability,
    moveSpotlight,
    holdingData,
  } = useContext(CaseflowContext) || {};

  const [availableActions, setAvailableActions] = useState({});

  // console.log('caseState', caseState);
  // console.log('caseFacts', caseFacts);
  //   console.log('caseActionsStation', caseActionsStation);
  // console.log('caseActionsAll', caseActionsAll);
  //console.log('caseFlowCase', caseFlowCase);
  // console.log('availableActions', availableActions);

  useEffect(() => {
    if (caseFacts && filterAvailableCFCActions) {
      handleActionFiltering(
        caseFacts,
        caseState,
        filterAvailableCFCActions,
        setAvailableActions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseActionsStation,
    caseFacts,
    caseFacts?.delegate_repairer_name,
    caseFacts?.preliminary_assessment,
    caseFacts?.goodwill_override_initial,
    caseFacts?.final_assessment,
    caseFacts?.local_repairer,
    caseState,
  ]);

  if (
    !caseFlowCase ||
    !setShowModal ||
    !setModalSettings ||
    !caseState ||
    !caseActionsStation ||
    !caseActionsAll ||
    !executeAction ||
    !alterCaseFacts ||
    !caseFacts ||
    !caseId ||
    !caseAlterability ||
    !moveSpotlight ||
    !holdingData
  )
    return (
      <CardWrapperWithHeader title="Claim actions">
        <LoadingSpinner size="xs" extraClasses="min-h-[128px]" />
      </CardWrapperWithHeader>
    );

  // Turn the actions into an array for mapping and add relevant data to them
  const actionsArray = Object.entries(
    caseState === CaseFlowCaseStations.INITIALLY_ASSESSED ||
      caseState === CaseFlowCaseStations.PRELIMINARY_ASSESSMENT ||
      caseState === CaseFlowCaseStations.FINAL_ASSESSMENT ||
      caseState === CaseFlowCaseStations.REPAIR ||
      caseState === CaseFlowCaseStations.CONFIRM_QUOTATION_RESPONSE
      ? availableActions
      : caseActionsStation
  ).map(([key, value]) => {
    return {
      ...(value as PossibleActionEntry),
      Icon: getActionIcon(key),
    };
  });

  const modalSettingOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: t('Assign case'),
      },
      children: <AssignCaseInternalModal />,
    },
    preliminary_assessment: {
      kind: 'generic' as const,
      settings: {
        title: t('Preliminary assessment'),
      },
      children: <DetermineCoverageModal />,
    },
    final_assessment: {
      kind: 'generic' as const,
      settings: {
        title: t('Final assessment'),
      },
      children: <FinalAssessmentModal />,
    },
    request_customer_send_item: {
      kind: 'generic' as const,
      settings: {
        title: t('Request customer to send item'),
        primaryButtonText: t('Send request to customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <RequestToSendItemModal />,
    },
    quote_customer: {
      kind: 'generic' as const,
      settings: {
        title: t('Quote customer'),
        primaryButtonText: t('Send quote'),
        secondaryButtonText: t('Cancel'),
      },
      children: <QuoteClaimModal />,
    },
    assign_to_authorised_repairer: {
      kind: 'generic' as const,
      settings: {
        title: t('Assign to authorised repairer'),
        primaryButtonText: t('Assign to a repairer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <AssignClaimToThirdPartyModal />,
    },
    replace_part_or_product: {
      kind: 'generic' as const,
      settings: {
        title: t('Replace parts or product'),
        primaryButtonText: t('Confirm'),
        secondaryButtonText: t('Cancel'),
      },
      children: (
        <ReplacePartsOrProductModal
          repair_work_registry={caseFacts?.repair_work_registry ?? []}
        />
      ),
    },
    log_item_received: {
      kind: 'generic' as const,
      settings: {
        title: t('Log item as received'),
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <LogItemReceivedModal />,
    },
    log_authorised_repairer_repair_complete: {
      kind: 'generic' as const,
      settings: {
        title: t('Log repair as complete'),
        primaryButtonText: t('Log repair as complete'),
        secondaryButtonText: t('Cancel'),
      },
      children: <LogRepairCompleteModal />,
    },
    confirm_customer_received_item: {
      kind: 'generic' as const,
      settings: {
        title: t('Confirm customer received item'),
        primaryButtonText: t('Confirm and close case'),
        secondaryButtonText: t('Cancel'),
      },
      children: <ConfirmItemReceivedModal />,
    },
    log_postage: {
      kind: 'generic' as const,
      settings: {
        title: t('Log postage'),
        primaryButtonText: t('Log postage'),
        secondaryButtonText: t('Cancel'),
      },
      children: (
        <LogPostageModal postage_registry={caseFacts?.postage_registry ?? []} />
      ),
    },
    message_customer: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <ContactCustomerModal messageCustomer />,
    },
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <ContactCustomerModal />,
    },
    log_repair_work: {
      kind: 'generic' as const,
      settings: {
        title: t('Log repair work'),
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <LogRepairWorkModal />,
    },
    approve_case_for_repair: {
      kind: 'generic' as const,
      settings: {
        title: t('Approve case for repair'),
        primaryButtonText: t('Approve for repair'),
        secondaryButtonText: t('Cancel'),
      },
      children: <ApproveCaseForRepairModal />,
    },
    reject_claim: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <RejectClaimModal />,
    },
    customer_collection: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <CustomerCollectionModal />,
    },
    assign_to_local_repairer: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Log repair work'),
        secondaryButtonText: t('Cancel'),
      },
      children: <LocalRepairModal />,
    },
    complete_repair: {
      kind: 'generic' as const,
      settings: {
        title: t('Contact customer'),
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <CompleteRepairModal />,
    },
    // Only if case is assigned to local repairer, i.e. local_repairer fact exists
    complete_local_repair_and_close_case: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Complete repair and close case'),
        secondaryButtonText: t('Cancel'),
      },
      children: <CompleteRepairAndCloseModal />,
    },
    close_case: {
      kind: 'generic' as const,
      settings: {
        primaryButtonText: t('Close case'),
        secondaryButtonText: t('Cancel'),
      },
      children: <CloseCaseModal />,
    },
  };

  return (
    <CardWrapperWithHeader title="Claim actions">
      <div className="flex gap-[40px] overflow-auto pb-4 lg:pb-2">
        {caseAlterability === 'AwaitingUpdate' ? (
          <div className="mx-auto items-center">
            <LoadingSpinner size={'xs'} />
          </div>
        ) : (
          actionsArray.map((action) => {
            const key = action.key;
            return (
              <ButtonIconVertical
                key={key}
                kind={'claim-actions'}
                label={action.label}
                Icon={action.Icon}
                onClick={() => {
                  setModalSettings(
                    modalSettingOptions[key as keyof typeof modalSettingOptions]
                  );
                  setShowModal(true);
                }}
              />
            );
          })
        )}

        {actionsArray.length === 0 && (
          <p className="font-nunito">
            {t('There are no available actions at the moment for this claim.')}
          </p>
        )}
      </div>
    </CardWrapperWithHeader>
  );
}

export default ClaimActionsType1;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */
const CASEFLOW_CONDITIONAL_ACTIONS: any = {
  /* --------------------------------- SHELTA --------------------------------- */
  preliminary_assessment: {
    delegate_unassigned: ['assign_case'],
    delegate_assigned: ['preliminary_assessment'],
  },
  initially_assessed: {
    fully_covered: [
      'assign_to_authorised_repairer',
      'request_customer_send_item',
      'replace_part_or_product',
      'assign_to_local_repairer',
    ],
    partially_covered_or_not_covered: [
      'assign_to_authorised_repairer',
      'quote_customer',
      'reject_claim',
    ],
    further_inspection: [
      'assign_to_authorised_repairer',
      'request_customer_send_item',
      'close_case',
      'assign_to_local_repairer',
    ],
  },
  final_assessment: {
    no_fa: ['final_assessment'],
    fa_fully_covered: ['approve_case_for_repair'],
    fa_partially_covered: ['quote_customer', 'close_case'],
    fa_not_covered: ['quote_customer', 'close_case'],
    fa_other_outcomes: [
      'approve_case_for_repair',
      'quote_customer',
      'close_case',
    ],
  },
  repair: {
    has_local_repairer: [
      'log_repair_work',
      'complete_local_repair_and_close_case',
    ],
    no_local_repairer: ['log_repair_work', 'complete_repair'],
  },
  confirm_quotation_response: {
    cqr_partial_or_none: [
      'quote_customer',
      'close_case',
      'approve_case_for_repair',
    ],
    cqr_other: [
      'quote_customer',
      'close_case',
      'replace_part_or_product',
      'request_customer_send_item',
      'assign_to_local_repairer',
    ],
  },
};

const handleActionFiltering = (
  caseFacts: Partial<CaseFactsShape>,
  caseState: string,
  filterAvailableCFCActions: (
    station: string,
    condition: string,
    CASEFLOW_CONDITIONAL_ACTIONS: any
  ) => { [key: string]: any } | null, // todo type any
  setAvailableActions: React.Dispatch<React.SetStateAction<any>> // todo type
) => {
  const {
    delegate_repairer_name,
    preliminary_assessment,
    goodwill_warranty_initial,
    goodwill_warranty_final,
    final_assessment,
    local_repairer,
  } = caseFacts;

  let filteredActions: { [key: string]: any } | null = {};

  const filter = (station: string, condition: string) => {
    filteredActions = filterAvailableCFCActions(
      station,
      condition,
      CASEFLOW_CONDITIONAL_ACTIONS
    );
  };

  const getAssessment = (stage: 'initial' | 'final') => {
    if (stage === 'initial') {
      return goodwill_warranty_initial || preliminary_assessment;
    } else {
      return goodwill_warranty_final || final_assessment;
    }
  };
  // Station === Preliminary assessment
  if (caseState === CaseFlowCaseStations.PRELIMINARY_ASSESSMENT) {
    filter(
      'preliminary_assessment',
      delegate_repairer_name === 'Unassigned'
        ? 'delegate_unassigned'
        : 'delegate_assigned'
    );
  }
  // Station === Initially assessed
  if (caseState === CaseFlowCaseStations.INITIALLY_ASSESSED) {
    if (getAssessment('initial') === 'Fully covered') {
      filter('initially_assessed', 'fully_covered');
    }
    if (
      getAssessment('initial') === 'Partially covered' ||
      getAssessment('initial') === 'Not covered'
    ) {
      filter('initially_assessed', 'partially_covered_or_not_covered');
    }
    if (getAssessment('initial') === 'Further inspection required') {
      filter('initially_assessed', 'further_inspection');
    }
  }
  // Station === Final assessment
  if (caseState === CaseFlowCaseStations.FINAL_ASSESSMENT) {
    if (!final_assessment) {
      filter('final_assessment', 'no_fa');
    } else if (getAssessment('final') === 'Fully covered') {
      filter('final_assessment', 'fa_fully_covered');
    } else if (getAssessment('final') === 'Partially covered') {
      filter('final_assessment', 'fa_partially_covered');
    } else if (getAssessment('final') === 'Not covered') {
      filter('final_assessment', 'fa_not_covered');
    } else {
      filter('final_assessment', 'fa_other_outcomes');
    }
  }

  // Station === Repair
  if (caseState === CaseFlowCaseStations.REPAIR) {
    filter(
      'repair',
      local_repairer ? 'has_local_repairer' : 'no_local_repairer'
    );
  }

  // Station === Confirm Quotation Response
  if (caseState === CaseFlowCaseStations.CONFIRM_QUOTATION_RESPONSE) {
    if (
      getAssessment('final') === 'Partially covered' ||
      getAssessment('final') === 'Not covered'
    ) {
      filter('confirm_quotation_response', 'cqr_partial_or_none');
    } else {
      filter('confirm_quotation_response', 'cqr_other');
    }
  }

  setAvailableActions(filteredActions);
};
