import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FunnelIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import {
  ButtonIcon,
  LoadingSpinner,
  ProductsAndPartsFilters,
  SearchBar,
  Table,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import {
  DTVendable,
  DTWarranty_Template,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import placeholderImg from '../../../assets/images/placeholder-image.png';
import {
  formatVendableDataForEditModal,
  generateFilterOptions,
  VendableEditorContext,
} from '@rabbit/bizproc/react';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import { formatAndGetLastCategory } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ModalAddEditItemKind } from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { ConfigContext } from '@rabbit/config/context';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export interface ProductsTabProps {
  handleEditModal: (
    kind: ModalAddEditItemKind,
    title: string,
    itemDetails?: any
  ) => void;
}

interface FilterOptionShape {
  checkboxName: string;
  label: string;
  count: number;
}

interface FacetFilterShape {
  name: string;
  value: string;
}

interface AppliedFilterShape {
  category: string;
  query: string;
}

export function ProductsTab({ handleEditModal }: ProductsTabProps) {
  /* ---------------------------------- Todos --------------------------------- */
  // TODO: Build the table data using the values in context
  // TODO: DEBOUNCE THE SHIT OUT OF THE TEXT SEARCH - DC
  // Look for the useDebounce hook in the code and replicate that here
  const { config } = useContext(ConfigContext);
  const contextValues = useContext(VendableEditorContext);
  const {
    vendableList,
    categoryList,
    searchParams,
    changeSearchParams,
    totalHits,
    totalPages,
    getWarrantyTemplates,
    shouldRefreshList,
  } = contextValues;
  const { warrantorPersona } = useGetMySagePersonas();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ITEMS_PER_PAGE = searchParams?.hitsPerPage ?? 5;
  const [filterOptions, setFilterOptions] = useState<FilterOptionShape[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilterShape>({
    category: '',
    query: '',
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: ITEMS_PER_PAGE,
  });

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isResultsFiltered, setIsResultsFiltered] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [warrantyTemplates, setWarrantyTemplates] = useState<
    DTWarranty_Template[]
  >([]);

  useEffect(() => {
    getWarrantyTemplates().then((data) => {
      setWarrantyTemplates(data);
    });
  }, []);

  // Format the results
  const formatResultsForProductsTable = (results: DTVendable[]) => {
    const formattedResults = results.map((result, i) => {
      const templateTitle = warrantyTemplates.find(
        (template) => template.docid === result.defaultTemplate
      )?.title;

      return {
        imageUrl: result?.img?.[0] ?? placeholderImg,
        upc: result.upc,
        docid: result.docid,
        productImage: (
          <div
            style={{
              backgroundImage: `url(${result?.img?.[0] ?? placeholderImg})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '95px',
              height: '95px',
            }}
          ></div>
        ),
        productName: result.full,
        sku: result.mpn,
        category:
          result?.category && Array.isArray(result?.category)
            ? formatAndGetLastCategory(result?.category)
            : '-',
        warrantyPlan: templateTitle ?? '-',
        deprecated: result.deprecated ? t('Yes') : t('No'),
        status: result?.published ? (
          <div className="inline overflow-hidden rounded bg-green-100 py-1 px-2">
            {t('Published')}
          </div>
        ) : (
          <div className="inline overflow-hidden rounded bg-gray-100 py-1 px-2">
            {t('Unpublished')}
          </div>
        ),
        actions: (
          <div className="flex justify-end gap-2 px-2 text-right">
            <PencilSquareIcon
              className="text-primary-900 h-[20px] w-[20px]"
              onClick={() =>
                handleEditModal(
                  ModalAddEditItemKind.products,
                  t('Edit product'),
                  formatVendableDataForEditModal(result)
                )
              }
            />
          </div>
        ),
      };
    });

    return formattedResults;
  };

  useEffect(() => {
    if (vendableList.length) {
      setFilterOptions(generateFilterOptions(categoryList ?? []));
    }
  }, [vendableList.length]);

  //@TEST_REMOVE_LATER
  useEffect(() => {
    if (searchParams && changeSearchParams)
      changeSearchParams({
        searchQuery: appliedFilters.query,
        page: pagination.pageIndex,
        //facetFilters: [{ name: 'categories', value: appliedFilters.category }],
      });
  }, [appliedFilters.query, pagination.pageIndex, appliedFilters.category]);

  if (!vendableList.length)
    return <LoadingSpinner size="md" extraClasses="mt-16" />;

  const handleFilterOptions = (filterLabel: string) => {
    setIsResultsFiltered(!isResultsFiltered);
    !isResultsFiltered
      ? setAppliedFilters({ category: filterLabel, query: '' })
      : setAppliedFilters({ category: '', query: '' });
  };

  return (
    <div className="flex flex-col">
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[50px] left-5 flex items-center space-x-4">
          <div className="w-[560px]">
            <SearchBar
              placeholder={t('Search by name, category or product name')}
              value={appliedFilters.query}
              submitSearchCallback={() => console.log('submit search')}
              handleChangeCallBack={(e) =>
                setAppliedFilters({ ...appliedFilters, query: e.target.value })
              }
            />
          </div>
        </div>
        <div className="absolute top-[60px] right-5 flex space-x-4">
          <div className="relative z-10">
            <div>
              <ButtonIcon
                label={t('Filters')}
                iconLeft={true}
                Icon={FunnelIcon}
                kind={'bgLightGreen'}
                onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
              />
            </div>
            {isFilterModalOpen && (
              <div className="absolute right-0 top-10 z-20">
                <ProductsAndPartsFilters
                  title={t('Category')}
                  items={filterOptions}
                  onChange={handleFilterOptions}
                  activeCategory={appliedFilters.category}
                />
              </div>
            )}
          </div>

          {config.VENDABLES.EDITOR.CAN_ADD_NEW.includes(
            (warrantorPersona?.personaId?.[0] as PersonaTypeSingleLetter) ?? ''
          ) && (
            <div>
              <ButtonIcon
                label={t('Add product')}
                iconLeft={true}
                onClick={() =>
                  handleEditModal(
                    ModalAddEditItemKind.products,
                    t('New product')
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
      <Table
        columns={tableHeaders}
        data={formatResultsForProductsTable(vendableList)}
        manualPagination
        manualSorting
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={totalHits}
        initialState={{
          columnPinning: { right: ['actions'] },
          pagination: { pageSize: ITEMS_PER_PAGE, pageIndex: 0 },
        }}
        useCallbackClickRow={(data: { docid: string; productName: string }) => {
          navigate(ROUTE_NAME.PRODUCTS + '/' + data.docid, {
            state: { docid: data.docid, productName: data.productName },
          });
        }}
        state={{
          isLoading: !vendableList.length || shouldRefreshList !== false,
          pagination,
          sorting,
        }}
      />
    </div>
  );
}

export default ProductsTab;

export const tableHeaders = [
  {
    header: '',
    accessorKey: 'productImage',
    enableSorting: false,
    size: 80,
  },
  {
    header: 'product name',
    accessorKey: 'productName',
    size: 230,
  },
  {
    header: 'sku',
    accessorKey: 'sku',
  },
  {
    header: 'category',
    accessorKey: 'category',
    size: 100,
  },
  {
    header: 'warranty plan',
    accessorKey: 'warrantyPlan',
    size: 100,
  },
  {
    header: 'deprecated',
    accessorKey: 'deprecated',
    size: 100,
  },
  {
    header: 'status',
    accessorKey: 'status',
    size: 100,
  },
  {
    header: '',
    accessorKey: 'actions',
    enableSorting: false,
    size: 50,
  },
];
