import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ConsumerIssueTypeHookOrigins,
  FileStorageContext,
  getCompletedUploadsOfCategory,
  LIST_COUNTRIES,
  useManageConsumerIssueTypes,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NewClaimModalSteps } from '../../../ModalNewClaim/ModalNewClaim';
import {
  DTConsumer_Public,
  DTHoldingProxy,
  UserUploadedDocument,
} from '@rabbit/data/types';
import {
  Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape,
  Caseflow_CaseCreation_Sage_Shelta_FormDataShape,
  CaseflowCaseTypes,
  RegisterCaseOrigins,
} from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { SageFileUploader } from '../../../upload-wrapper/SageFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useGetMySagePersonas } from '@rabbit/data/portal';

// TODO: EXTRACT GENERIC BITS OF THIS AND NCCHF_Base INTO A SHARED COMPONENT
// TODO: Add a check for existing uploaded serial proofs (from olive)
// TODO: Internal comment / external repairer comment variation
export interface NCCHF_FatbikesProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: { holding: DTHoldingProxy; consumer: DTConsumer_Public };
}

interface HoldingDataCase {
  consumer_name: string;
  purchase_date: Date;
  consumer_issue_type_ref: string;
  purchase_location: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  product_country: string;
  consumer_issue_description: string;
  holding_vendable_id: string;
  serial_number: string;
  serial_number_proof: UserUploadedDocument[];
  internal_comment: string;
  consumer_claim_evidence: UserUploadedDocument[];
}

export function NCCHF_Fatbikes(props: NCCHF_FatbikesProps) {
  const { handleClose, onChange, data, onHoldingCreated, setLoading } = props;
  const personas = useGetMySagePersonas();
  const formikRef = useRef(null) as any;
  const { t } = useTranslation();
  const activePremiumTenantLink = t('tenantLink');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);

  const { initiateCaseFlowCase } = useSageAPI();

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  //console.log(data, 'data'); // @TEST_REMOVE_LATER
  const initialValues: HoldingDataCase = {
    consumer_name: data.consumer?.fullname,
    holding_vendable_id: '',
    purchase_date: new Date(),
    consumer_issue_type_ref: '',
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    product_country: '',
    consumer_issue_description: '',
    internal_comment: '',
    serial_number: data.holding?.serial ?? '',
    serial_number_proof: data.holding?.serial_proof ?? [],
    consumer_claim_evidence: [],
  };

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};

  const validationSchema = Yup.object().shape(
    data.holding
      ? {
          consumer_name: Yup.string().trim(),
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required('Issue type must be selected'),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
          serial_number: Yup.string()
            .trim()
            .required('Please enter a serial number'),
          // serial_number_proof: Yup.array()
          //   .min(1)
          //   .required('Please upload a photo of the serial number'),
          consumer_claim_evidence: Yup.array(),
        }
      : {
          consumer_name: Yup.string().trim(),
          holding_vendable_id: Yup.string()
            .trim()
            .required("Product name can't be empty"),
          purchase_date: Yup.string().nullable().trim(),
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required('Issue type must be selected'),
          purchase_location: Yup.mixed(),
          store_not_listed: Yup.boolean(),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
          serial_number: Yup.string()
            .trim()
            .required('Please enter a serial number'),
          // serial_number_proof: Yup.array()
          //   .min(1)
          //   .required('Please upload a photo of the serial number'),
          consumer_claim_evidence: Yup.array(),
        }
  );

  const { completed } = uploadQueueState ?? {};

  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );

  const caseEvidenceuploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.ConsumerCaseEvidence
    );

  // TODO: check this on submit func to save the case
  const onSubmit = async (values: HoldingDataCase) => {
    setIsSubmitting(true);
    setLoading(true);

    const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const caseEvidenceFiles = caseEvidenceuploads?.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerCaseEvidence &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.product_country
        : values.purchase_location?.country ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;

    const finalData: Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape =
      data.holding
        ? {
            consumer_first_name: data.consumer.splitname?.first,
            consumer_last_name: data.consumer.splitname?.last,
            consumer_address: data.consumer.address,
            consumer_email: data.consumer.email,
            consumer_telephone: data.consumer.phone,
            consumer_preferred_contact: data.consumer.preferred_contact,
            holding_vendable_id: data.holding.vendable,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: data.holding.purchase_time,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            consumer_issue_type_ref: values.consumer_issue_type_ref,
            serial_number: values.serial_number,
            serial_number_proof: serialNumberProofFiles ?? [],
            consumer_claim_evidence: caseEvidenceFiles ?? [],
          }
        : ({
            ...data,
            ...values,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: values.purchase_date
              ? OurDateTime.dateToTimestamp(values.purchase_date)
              : null,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            consumer_issue_type_ref: values.consumer_issue_type_ref,
            serial_number: values.serial_number,
            serial_number_proof: serialNumberProofFiles ?? [],
            consumer_claim_evidence: caseEvidenceFiles ?? [],
          } as any);

    const repairerLink = personas.repairerPersona?.personaId;
    const warrantorLink = personas.warrantorPersona?.personaId;

    try {
      const res = await initiateCaseFlowCase({
        origin: RegisterCaseOrigins.SAGE_FATBIKES,
        caseType: CaseflowCaseTypes.FATBIKES,
        tenantLink: activePremiumTenantLink,
        consumerLink: data.consumer.docid,
        holdingLink: data.holding?.holdingLink,
        // use repairerLink if no warrantorLink is present
        ...(repairerLink &&
          !warrantorLink && {
            repairerLink,
          }),
        ...(warrantorLink && { warrantorLink }),
        formData: finalData,
      });

      // console.log('res', res);
      if (res.holdingLink && res.consumerLink) {
        onHoldingCreated({
          case_id: res.caseId,
          consumer_persona_id: res.consumerLink,
          holding_id: res.holdingLink,
        });

        if (
          moveCompletedUploadsToAttached &&
          updateHoldingWithFiles &&
          serialNumberProofFiles &&
          serialNumberProofFiles.length > 0
        ) {
          await updateHoldingWithFiles(
            res.holdingLink,
            serialNumberProofFiles,
            UploadedFileCategories.SerialNumberProof
          );
          moveCompletedUploadsToAttached(serialNumberProofUploads);
        }

        if (
          moveCompletedUploadsToAttached &&
          caseEvidenceFiles &&
          caseEvidenceFiles.length > 0
        ) {
          moveCompletedUploadsToAttached(caseEvidenceuploads);
        }
      }

      handleClose();
    } catch (err: any) {
      console.log(err);
      toast.error(err?.message ?? 'Something went wrong');
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formikRef && formikRef.current && isDatePurchaseDisabled) {
      formikRef.current.setFieldValue('purchase_date', null);
    }
  }, [isDatePurchaseDisabled]);

  if (!data.holding) {
    return (
      <div className="p-5">
        <p className="pb-3">
          {t(
            'No valid products were found for this consumer! They might not exist, or have cases created for them already.'
          )}
        </p>
        <div className="flex">
          <Button kind="primary" onClick={() => onChange('select-user')}>
            {t('Search again')}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {({ errors, values, isValid, dirty, setFieldValue }) => (
        <Form className="flex-col px-5 gap-3">
          <div className="w-full mt-4">
            <Input
              type="text"
              name="consumer_name"
              label={t('Customer name')}
              settings={{
                id: 'consumer_name',
                placeholder: t('Customer name'),
                disabled: true,
              }}
            />
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            {!data.holding && (
              <>
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label="Product*"
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: 'Product name',
                    options: [],
                    errors: errors,
                    hint: '*required',
                    tenantLink: t('tenantLink'),
                  }}
                />
                <div className="flex-col w-full">
                  <Input
                    type="datepicker"
                    name="purchase_date"
                    label="Date of purchase"
                    settings={{
                      id: 'purchase_date',
                      placeholder: 'Date of purchase',
                      maxDate: new Date(),
                      disabled: isDatePurchaseDisabled,
                    }}
                  />
                  <div className="flex items-center pt-2">
                    <Input
                      type="checkbox"
                      name="noDateOfPurchase"
                      settings={{
                        checkboxLabel: 'Date not known',
                        checkboxLabelStyles: 'text-base text-gray-500',
                        onChange: () =>
                          setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`mt-4 grid gap-4 ${
              data.holding ? 'grid-cols-1' : 'grid-cols-2'
            } `}
          >
            <Input
              type="select"
              name="consumer_issue_type_ref"
              label={t('Customer issue type*')}
              settings={{
                isMulti: false,
                id: 'consumer_issue_type_ref',
                placeholder: t('Product defect'),
                options: consumerIssueTypeOptions,
                errors: errors,
                hint: '*required',
              }}
            />
            {!data.holding && (
              <div className="flex flex-col grow">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('Store of purchase')}
                  settings={{
                    isMulti: false,
                    placeholder: t('Please select an option'),
                    options: [],
                    errors: errors,
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('Store is not listed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="flex flex-col mt-2 gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('Enter store name'),
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t('Country where product was purchased'),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center mt-4 gap-4">
            <Input
              type="text"
              name="consumer_issue_description"
              label={t('Customer issue description')}
              settings={{
                id: 'consumer_issue_description',
                placeholder: t('Enter text here'),
                allowSpecialCharacter: true,
              }}
            />
          </div>

          <div className="flex items-center mt-4 gap-4">
            <Input
              type="text"
              name="serial_number"
              label={t('Product serial number*')}
              settings={{
                id: 'serial_number',
                placeholder: t('Enter serial number here'),
                allowSpecialCharacter: true,
                hint: '*required',
                value: data.holding?.serial ?? '',
              }}
            />
          </div>
          <SageFileUploader
            label={t('Photo of serial number')}
            identifiers={{
              category: UploadedFileCategories.SerialNumberProof,
              docType: {
                docid: data.holding.holdingLink,
                type: DocTypeShapeTypes.Holding,
              },
              personaId: data.consumer.docid ?? '',
            }}
            maxFiles={1}
            onDeleteFile={() => setFieldValue('serial_number_proof', [])}
            currentFiles={values.serial_number_proof ?? []}
            accepts={['image/*', '.pdf']}
          />
          <SageFileUploader
            label={t('Supporting evidence')}
            identifiers={{
              category: UploadedFileCategories.ConsumerCaseEvidence,
              docType: {
                docid: data.holding.holdingLink,
                type: DocTypeShapeTypes.Case,
              },
              personaId: data.consumer.docid ?? '',
            }}
            accepts={['image/*', 'video/*', '.pdf']}
          />

          <div className="flex items-center mt-4 gap-4">
            <Input
              type="text"
              name="internal_comment"
              label={t('Internal comment')}
              settings={{
                id: 'internal_comment',
                placeholder: t('Enter text here'),
                allowSpecialCharacter: true,
              }}
            />
          </div>

          <div className="flex w-full mt-5 gap-4">
            <Button
              kind="primary"
              type="submit"
              disabled={
                !dirty || Object.keys(errors).length > 0 || isSubmitting
                // (serialNumberProofUploads.length === 0 &&
                //   values.serial_number_proof.length === 0)
              }
              loading={isSubmitting}
            >
              {t('Create claim')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NCCHF_Fatbikes;
