import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { OptionShape, useGetRepairerPeers } from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { CFCF_RepairWorkRegistryItem } from '@rabbit/bizproc/core';
import { t } from 'i18next';

export interface RepairTimeModalProps {
  index?: number | null;
  data?: FormValueShape | null;
  remove?: boolean | null;
}

interface FormValueShape {
  delegate_repairer_id: string;
  timeStamp: string;
  total_repairing_time: string;
}

const validationSchema = Yup.object().shape({
  delegate_repairer_id: Yup.string()
    .trim()
    .required('Please select a technician'),
  timeStamp: Yup.string().trim(),
  total_repairing_time: Yup.string().trim(),
});

export function RepairTimeModal({ index, data, remove }: RepairTimeModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : repairerPeer.map((repairer, index) => ({
        id: `${index}`,
        label: repairer?.name || '',
        value: repairer?.docid || '',
      }));
  const [usersForAssignment, setUsersForAssignment] = useState(repairerUsers);

  const initialValues: FormValueShape = {
    delegate_repairer_id: data?.delegate_repairer_id ?? '',
    timeStamp: data?.timeStamp ?? '',
    total_repairing_time: data?.total_repairing_time ?? '',
  };

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  useEffect(() => {
    setUsersForAssignment(repairerUsers);
  }, [repairerUsers]);

  const getRepairerName = (id: string) => {
    const repairer = usersForAssignment.find((user) => user.value === id);
    return repairer?.label;
  };

  const onDelete = async () => {
    setIsSubmitting(true);
    //const updatedRegistry = caseFacts.repair_work_registry?.filter((registry, i) => i !== index);
    //As requested, we are not deleting the registry, but just removing the data - VP
    const updatedRegistry = caseFacts.repair_work_registry?.map(
      (registry: CFCF_RepairWorkRegistryItem, i: Number) => {
        if (i === index) {
          return {
            ...registry,
            delegate_repairer_id: '',
            delegate_repairer_name: '',
            timeStamp: null,
            total_repairing_time: '',
          };
        }
        return registry;
      }
    );

    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (values: FormValueShape) => {
    setIsSubmitting(true);
    const updatedRegistry = caseFacts.repair_work_registry?.map(
      (registry: CFCF_RepairWorkRegistryItem, i: number) => {
        if (i === index) {
          return {
            ...registry,
            delegate_repairer_id: values.delegate_repairer_id,
            delegate_repairer_name: getRepairerName(
              values.delegate_repairer_id
            ),
            timeStamp: values.timeStamp,
            total_repairing_time: values.total_repairing_time,
          };
        }
        return registry;
      }
    );

    try {
      await alterCaseFacts({ repair_work_registry: updatedRegistry });
      toast.success('Claim updated successfully.');
      setShowModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      settings={{
        title: t('Repair time'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      className={classNames('max-h-[768px] w-full max-w-[1024px]', {
        'max-w-[500px]': remove,
      })}
      isLoading={isSubmitting}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => {
          return (
            <Form className="mt-5 flex flex-col gap-3 px-4">
              {remove ? (
                <div>Are you sure you want to remove this repair time?</div>
              ) : (
                <>
                  <Input
                    type="select"
                    label="Team member*"
                    name="delegate_repairer_id"
                    settings={{
                      options: repairerUsers ?? [],
                      id: 'delegate_repairer_id',
                      placeholder: 'Please select an option',
                      hint: '*required',
                    }}
                  />
                  <div className="flex gap-3">
                    <Input
                      type="datepicker"
                      label="Date*"
                      name="timeStamp"
                      settings={{
                        id: 'timeStamp',
                        placeholder: 'Select a date',
                        hint: '*required',
                      }}
                    />
                    <Input
                      type="time"
                      label="Log time spent repairing*"
                      name="total_repairing_time"
                      settings={{
                        id: 'total_repairing_time',
                        hint: '*required',
                        errors: errors.total_repairing_time,
                      }}
                    />
                  </div>
                </>
              )}
              {/** DOUBLE CHECK RATE HERE */}
              {/* <div>
                <Input
                  type="currency"
                  name="parts_cost"
                  label='Rate*'
                  settings={{
                    placeholder: 'Per unit',
                    currency: appInfo.currency,
                    hint: '*required',
                  }}
                />
              </div> */}
              <div className="mt-4 flex gap-4">
                {remove ? (
                  <Button
                    kind="red"
                    type="submit"
                    onClick={onDelete}
                    disabled={isSubmitting}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button kind="primary" type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                )}
                <Button
                  kind="outline_red"
                  type="submit"
                  onClick={() => setShowModal(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RepairTimeModal;
