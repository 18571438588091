import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { CLAIM_CAUSE_OPTIONS } from '@rabbit/bizproc/react';
import { ButtonIcon } from '@rabbit/elements/shared-components';
import { SelectOptionShape } from '@rabbit/elements/shared-types';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function CauseTab() {
  const { t } = useTranslation();
  const CauseTable = useMemo(
    () =>
      CreateTable<SelectOptionShape>(CLAIM_CAUSE_OPTIONS, [
        {
          header: 'name',
          value: (cause) => cause.label,
        },
        {
          header: 'description',
          value: (cause) => '-',
        },
        {
          header: '',
          accessorKey: 'actions',
          value: (cause) => (
            <div className="text-center">
              <ButtonIcon
                label={t('Edit')}
                kind={'bgLightGreen'}
                iconLeft={true}
                Icon={PencilSquareIcon}
                onClick={() => 0}
              />
            </div>
          ),
          size: 15,
          maxSize: 50,
        },
      ]),
    []
  );

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={t('Add cause')}
              iconLeft={true}
              onClick={() => 0}
            />
          </div>
        </div>
      </div>
      <CauseTable
        initialState={{
          columnPinning: { right: ['actions'] },
          showGlobalFilter: true,
        }}
        muiSearchTextFieldProps={{
          placeholder: 'Search name or description',
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
      />
    </>
  );
}
