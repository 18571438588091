/* eslint-disable @nx/enforce-module-boundaries */
import { FileStorageContext } from '@rabbit/bizproc/react';
import {
  InputPicturev2,
  LoadingSpinner,
  UploaderFormikProvider,
  UploadFilesFormv2,
} from '@rabbit/elements/shared-components';
import {
  FileUploaderVariations,
  FileUploaderWrapperProps,
} from '@rabbit/elements/shared-types';
import { useContext, useEffect, useState } from 'react';
import { SageFileUploadAutoUpdater } from './SageFileUploadAutoUpdater';
import { t } from 'i18next';

// TODO: Handle all caseflow related operations here and in the context - dc
export function SageFileUploader(props: FileUploaderWrapperProps) {
  const {
    name,
    label = t('Upload files'),
    labelClasses,
    variation = FileUploaderVariations.FormItem,
    identifiers,
    currentFiles,
    accepts,
    onUploadCompleted,
    onDeleteFile,
    alterCaseFacts,
    shouldAutoUpdateDocs,
    disabled,
    refs,
  } = props;
  const [currentFilesState, setCurrentFilesState] = useState(currentFiles);
  const fileStorageContext = useContext(FileStorageContext);
  const {
    uploadFiles,
    uploadQueueState,
    isUpdating,
    deleteFile,
    shouldRefetch,
    setShouldRefetch,
    clearAttached,
    unusedCompletedCleanup,
  } = fileStorageContext || {};

  // If we don't have any current files from the parent, we might have to fetch them
  useEffect(() => {
    if ((!currentFiles || shouldRefetch) && identifiers.docType?.docid) {
      setCurrentFilesState(currentFiles ?? []);
      setShouldRefetch?.(false);
    }

    // clear attached files from state once currentFiles has been updated
    if (
      currentFiles &&
      uploadQueueState?.attached &&
      uploadQueueState?.attached.length > 0
    ) {
      setCurrentFilesState(currentFiles ?? []);
      if (uploadQueueState?.attached) {
        const filesToBeCleared = uploadQueueState.attached.flatMap((file) =>
          file.category === identifiers.category ? [file] : []
        );
        clearAttached?.(filesToBeCleared);
      }
    }
  }, [
    shouldRefetch,
    currentFiles,
    identifiers.category,
    identifiers.docType,
    uploadQueueState?.attached,
  ]);

  if (
    !uploadFiles ||
    !uploadQueueState ||
    !currentFilesState ||
    !deleteFile ||
    !setShouldRefetch ||
    isUpdating === undefined
  )
    return <LoadingSpinner size="sm" />;

  const formItemProps = {
    ...props,
    name,
    queue: uploadQueueState,
    currentFiles: currentFilesState,
    isUpdating,
    actions: {
      uploadFiles,
      deleteFile,
      alterCaseFacts,
      setShouldRefetch,
    },
    onDeleteFile,
    onUploadCompleted,
    disabled,
  };
  return (
    <>
      {shouldAutoUpdateDocs && (
        <SageFileUploadAutoUpdater fileCategory={identifiers.category} />
      )}
      {variation === FileUploaderVariations.FormItem && name && (
        <UploaderFormikProvider {...{ ...formItemProps, name }} />
      )}
      {variation === FileUploaderVariations.FormItem && !name && (
        <UploadFilesFormv2 {...formItemProps} />
      )}
      {/* Todo finish integrating this variation into UploaderFormikProvider */}
      {variation === FileUploaderVariations.InputPicture && name && (
        <InputPicturev2
          {...props}
          // todo make both types of accepts work (string[] and string)
          accepts="image/*"
          name={name}
          queue={uploadQueueState}
          currentFiles={currentFilesState}
          isUpdating={isUpdating}
          selectFileRef={refs?.selectFileRef}
          removeFileRef={refs?.removeFileRef}
        />
      )}
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

// const fetchShippingLabelFiles = async (holdingId: string) => {

// }
